import {AbstractElement} from '../base/src/abstract-element';
import {Dom} from '../base/src/utils/dom';

export class TestMode extends AbstractElement {
    private static key: string = '';

    private static close(e) {
        localStorage.setItem('isTestMode', 'true');
        Dom.hide(e.target.parentNode.parentNode);

        e.preventDefault();
    }

    public init() {
        TestMode.key = this.element.getAttribute('data-key') !== null ?
            this.element.getAttribute('data-key') : 'isTestMode';

        if (localStorage.getItem('isTestMode') === null) {
            this.element.querySelector('.test-mode__close')
                .addEventListener('click', TestMode.close);
            if (this.element.querySelector('.test-mode__cross.test-mode__close') !== null) {
                this.element.querySelector('.test-mode__cross.test-mode__close')
                    .addEventListener('click', TestMode.close);
            }

            setTimeout(() => {
                Dom.show(this.element);
            }, this.element.getAttribute('data-time'));
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    Dom.querySelector('.test-mode__item', (element) => {
        const testMode = new TestMode(element);
    });
});
